import Jed from 'jed';
import './locale'

(function() {
    var locales = window.locales || {};
    var locale = document.getElementsByTagName('html')[0].lang;

    if(!locale) {
        console.warn('No locale found as an html attribute, using default.');
        return;
    }

    locale = locale.replace(/-/g, "_");

    var i18n = new Jed(Object.assign({domain: "app"}, locales[locale]));

    window.__mixin = (text, mixin) => {
        if (!mixin) return text;
        const re = new RegExp(`%\\{(${Object.keys(mixin).join(')\\}|%\\{(')})\\}`, 'g');
        return text.replace(re, m => {
            const v = mixin[m.slice(2, -1)];
            return v === undefined ? m : v;
        });
    };

    window.__ = (key, mixin) => {
        const text = i18n.gettext.call(i18n, key);
        return mixin ? __mixin(text, mixin) : text;
    };

    window.n__ = (skey, pkey, val, mixin) =>  {
        const text = i18n.ngettext.call(i18n, skey, pkey, val);
        return mixin ? __mixin(text, mixin) : text;
    };

    window.s__ = (key, mixin) => {
        const text = window.__(key, mixin).split('|').pop();
        return mixin ? __mixin(text, mixin) : text;
    };

    window.gettext = window.__;
    window.ngettext = window.n__;
    window.sgettext = window.s__;

    window.i18n = i18n;

    window.langs = {
        "en":{"symbol":"🇬🇧","name":"English"},
        "de":{"symbol":"🇩🇪","name":"Deutsch"},
        "fr":{"symbol":"🇫🇷","name":"Français"},
        // "it":{"symbol":"🇮🇹","name":"Italiano"},
        // "es":{"symbol":"🇪🇸","name":"Español"},
        // "pt":{"symbol":"🇵🇹","name":"Português"},
        // "nl":{"symbol":"🇳🇱","name":"Nederlands"},
        // "ru":{"symbol":"🇷🇺","name":"русский"},
        // "da":{"symbol":"🇩🇰","name":"Dansk"},
        // "fi":{"symbol":"🇫🇮","name":"Suomi"},
        // "no":{"symbol":"🇳🇴","name":"Norsk"},
        // "sv":{"symbol":"🇸🇪","name":"Svenska"}
        };
})();
